<template>
  <div class="steup_page">
    <div class="top">
      <div class="top_block">
        <div class="title">《全国足球特色幼儿园系统平台》介绍</div>
        <div class="text">
          《全国足球特色幼儿园系统平台》是按照全国校足办幼儿足球游戏活动指南的理念，以英国国家级幼儿园教育体系为基础，英国足总青少年发展管理体系为标准，经过国内外专家论证，由英国专业团队创建打造的目前全球最先进的幼儿足球游戏教学体系。其最大特点是遵循教育部提出的创新思维，打破传统幼儿体育教学中单一化、专项化、成人化的错误模式，以游戏的形式、多媒体加互联网的手段在一个充满创造力的情境中为3-6岁幼儿提供有利于激发学习探索、独立思考、团队协作的丰富运动体验，让孩子们通过体育运动去了解这个世界。
        </div>
      </div>
    </div>
    <div class="center">
      <titleComponent title="体 系 2.0" titleEnglish="ABOUT 2.0" />
      <div class="sys">
        <div class="block">
          <div class="sys_block" v-for="item in imgList" :key="item.title">
            <img :src="item.url" alt="" />
            <div class="title" v-html="item.title"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <titleComponent title="教学理念" titleEnglish="ABOUT TEACHING" />
      <img class="imgs" src="../../assets/img/home/steup/LN.png" alt="" />
    </div>
  </div>
</template>

<script>
import titleComponent from '@/components/home/title.vue'
export default {
  name: 'SteupBlock',
  components: {
    titleComponent,
  },
  data() {
    return {
      imgList: [
        {
          url: require('@/assets/img/home/steup/t1.png'),
          title: 'LGA游戏课程体系',
        },
        {
          url: require('@/assets/img/home/steup/t2.png'),
          title: 'PDA测试体系',
        },
        {
          url: require('@/assets/img/home/steup/t3.png'),
          title: '评估体系',
        },
        {
          url: require('@/assets/img/home/steup/t4.png'),
          title: '量化积分体系',
        },
        {
          url: require('@/assets/img/home/steup/t5.png'),
          title: '汪星嘉年华',
        },
        {
          url: require('@/assets/img/home/steup/t6.png'),
          title: '教师培训',
        },
        {
          url: require('@/assets/img/home/steup/t7.png'),
          title: '幼儿健康体智动态发展<br>比对数据库体系',
        },
        {
          url: require('@/assets/img/home/steup/t8.png'),
          title: '“全国幼儿足球国家级示范基地”<br>遴选建设内容',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.steup_page {
  width: 100%;
  .top {
    width: 100%;
    height: 757px;
    @include bg('../../assets/img/home/steup/txban.png');
    box-sizing: border-box;
    padding-top: 210px;
    padding-left: 180px;
    margin-bottom: 100px;
    text-align: left;
    .top_block {
      width: 640px;
      color: #ffffff;
      .title {
        font-family: AlibabaPuHuiTiB;
        font-size: 28px;
        line-height: 28px;
        letter-spacing: 3px;
        margin-bottom: 20px;
      }
      .text {
        font-family: AlibabaPuHuiTiR;
        font-size: 18px;
        line-height: 35px;
        letter-spacing: 2px;
      }
    }
  }
  .center {
    width: 100%;
    .sys {
      background-color: #cc1a30;
      color: #fff;
      margin-bottom: 100px;
      margin-top: 70px;
      .block {
        width: 60%;
        margin: 0 auto;
        padding: 80px 0;
        @include flex(row, space-between, center);
        flex-wrap: wrap;
        .sys_block {
          width: 180px;
          margin: 18px 40px;
          img {
            width: 160px;
            height: 160px;
            margin-bottom: 25px;
          }
        }
      }
    }
  }
  .bottom {
    img {
      width: 70%;
      margin: 80px 0;
    }
  }
  .imgs {
    padding-left: 280px;
  }
}
</style>
